@import url('https://fonts.googleapis.com/css2?family=Creepster&family=Source+Sans+Pro:wght@700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  filter: invert(1);

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */

  }
}

.App-header {
  background-color: #A9A9A9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Halloween Styles */
.main-container {
  width: 100vw;
  height: 100%; 
  background-image: url(./images/skeleton2.jpg);
  background-attachment: fixed;
  background-size: cover;
  min-height: 1100px;
  /* padding-left: 20px;
  padding-right: 20px; */
  padding-top:40px;
  
}



h1 {
  padding-bottom:10px;
  padding-top:0px;
}

.halloween-content 
{
  -webkit-text-stroke: .1px black;
  font-family: 'Source Sans Pro', sans-serif;
  color:white;
  font-size: 16px;
  background-color: hsla(0, 0%, 100%, 0.3);
  max-width: 75%;  
  min-height: 300px;
  border-radius: 10px;
  padding: 10px;
  margin-right: auto;
  margin-left: auto;
}

.halloween-titles
{
  max-width: 75%; 
  margin-right: auto;
  margin-left: auto;
}

.title {
  color: white;
  font-family: 'Creepster', cursive;
  font-size: 60px;
  letter-spacing: 2px;
}

.title2 {
  color: white;
  font-family: 'Creepster', cursive;
  font-size: 20px;
  letter-spacing: 2px;
}

.btn {
  background-color: #912914 !important;
  border-color: #838383 !important;
  min-width: 300px;
  margin-top: 5px;
}

.card-slide {
  max-width: 300px;
  background-color: hsla(0, 0%, 100%, 0.3);
  color: black;
}

.input {
  max-width: 300px;
}

.horror-text {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: flickering;
  animation-timing-function: step-start;
  color: #912914;
  font-family: 'Creepster', cursive;
}

@keyframes flickering {
  0% {
    color: #912914;
    text-shadow: none;
    transform: skewX(0);
  }
  5% {
    color: #912914;
    text-shadow: none;
    transform: skewX(0);
  }
  10% {
    color: #912914;
    text-shadow: 1px 1px #eee;
    transform: skewX(-5deg);
  }
  15% {
    color: #ccc;
    text-shadow: none;
    transform: skewX(-5deg);
  }
  20% {
    color: #912914;
    text-shadow: none;
    transform: skewX(0);
  }
  25% {
    color: #ccc;
    text-shadow: 2px 2px #912914;
    transform: skewX(0);
  }
  30% {
    color: #ccc;
    text-shadow: 2px 2px #912914;
    transform: skewX(0);
  }
  35% {
    color: #ccc;
    text-shadow: 2px 2px #912914;
    transform: skewX(0);
  }
  37% {
    color: #ccc;
    text-shadow: 1px 1px #eee;
    transform: skewX(0);
  }
  40% {
    color: #912914;
    text-shadow: 1px 1px #eee;
    transform: skewX(0);
  }
  45% {
    color: #912914;
    text-shadow: 1px 1px #eee;
    transform: skewX(0);
  }
  50% {
    color: #912914;
    text-shadow: none;
    transform: skewX(0);
  }
  55% {
    color: #912914;
    text-shadow: none;
    transform: skewX(0);
  }
  60% {
    color: #912914;
    text-shadow: none;
    transform: skewX(0);
  }
  65% {
    color: #912914;
    text-shadow: none;
    transform: skewX(0);
  }
  68% {
    color: #912914;
    text-shadow: 1px 1px #eee;
    transform: skewX(0);
  }
  70% {
    color: #912914;
    text-shadow: 1px 1px #eee;
    transform: skewX(0);
  }
  75% {
    color: #912914;
    text-shadow: 1px 1px #eee;
    transform: skewX(2deg);
  }
  80% {
    color: #912914;
    text-shadow: 1px 1px #eee;
    transform: skewX(2deg);
  }
  85% {
    color: #912914;
    text-shadow: 1px 1px #eee;
    transform: skewX(2deg);
  }
  90% {
    color: #912914;
    text-shadow: none;
    transform: skewX(0);
  }
  95% {
    color: #912914;
    text-shadow: 1px 1px #eee;
    transform: skewX(0);
  }
  100% {
    color: #912914;
    text-shadow: none;
    transform: skewX(0);
  }
}

